import PhoneInput from "react-phone-number-input/input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import Error from "../Error/index.js";
import "./styles.css";

import { useState } from "react";

const MobileInput = ({ inputHandler, input, errorHandler, label }) => {
  const [errorMsg, setErrorMsg] = useState("");

  function validateMobile(input, handler) {
    if (!input) {
      handler("Phone number length is invalid");
      console.log("input is invalid");
      return false;
    }
    if (!isPossiblePhoneNumber(input)) {
      handler("Phone number length is invalid");
      return false;
    } else {
      handler("");
      return true;
    }
  }

  return (
    <div className="field">
      <label htmlFor="clinicianMobile" class="label">
        {label}
      </label>
      <PhoneInput
        name="patientMobile"
        country="US"
        class="inputBox"
        value={input}
        onChange={inputHandler}
        // placeholder="(555) 555-5555"
        onBlur={() => {
          errorHandler(!validateMobile(input, setErrorMsg));
        }}
      />
      <Error msg={errorMsg} />
    </div>
  );
};

export default MobileInput;
