import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import background from "../assets/medical_icon_image.jpeg";
import Loader from "react-loader-spinner";

import { Formik, Form } from "formik";
import {
  sendInvite,
  createChat,
  createAccount,
  testReq,
} from "../API/index.js";
import FormInput from "../components/FormInput/index.js";
import "./styles.css";
// import PhoneInput from "react-phone-number-input/input";

import MobileInput from "../components/MobileInput/index.js";
import { useState } from "react";
export const SessionForm = () => {
  const [patientMobile, setPatientMobile] = useState();
  const [isError, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div
      className="main"
      style={{ backgroundImage: `url(${background})`, backgroundSize: "cover" }}
    >
      <Formik
        initialValues={{
          patientFirst: "",
          patientLast: "",
          email: "",
          dob: "",
        }}
        validationSchema={Yup.object({
          patientFirst: Yup.string()
            .max(40, "Must be 40 characters or less")
            .required("Patient first name is required"),
          patientLast: Yup.string()
            .max(40, "Must be 40 characters or less")
            .required("Patient last name is required"),
          email: Yup.string().email("Invalid email address"),
          dob: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (isError) return;
          // const phoneNumber = patientMobile.substring(2);
          const formVals = {
            ...values,
            patientMobile: patientMobile,
          };
          setLoading(true);
          let res = await createAccount(formVals);
          res = await sendInvite(patientMobile);
          res = await createChat(patientMobile);
          setLoading(false);
          resetForm();
          setPatientMobile("");
          setSubmitting(false);
        }}
      >
        <Form>
          <div className="formContainer">
            <FormInput
              label="*Patient First Name"
              name="patientFirst"
              type="text"
            />
            <FormInput
              label="*Patient Last Name"
              name="patientLast"
              type="text"
            />
            <FormInput label="Patient email" name="email" type="email" />
            <FormInput label="*Patient Date of Birth" name="dob" type="date" />
            <MobileInput
              label="*Patient Phone Number"
              inputHandler={setPatientMobile}
              input={patientMobile}
              errorHandler={setError}
            />
            <div className="btnWrapper">
              <button className="submitBtn" type="submit">
                <div>Submit</div>
                {loading && (
                  <Loader
                    type="TailSpin"
                    color="white"
                    height={20}
                    width={20}
                    timeout={10000}
                  />
                )}
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
