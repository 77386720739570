import axios from "axios";
import { base_url, org_id } from "./env_vars";
import { v4 as uuidv4 } from "uuid";

function formatDate(i_date) {
  const date_parts = i_date.split("-");
  return [date_parts[1], date_parts[2], date_parts[0]].join("-");
}

export async function getToken() {
  const config = {
    method: "post",
    url: base_url + "/user_management/self_login",
    data: {
      email: "superadmin@afya.net",
      password: "Aa123456@",
      force_login: 1,
    },
  };
  try {
    const res = await axios(config);
    return res.data.token;
  } catch (e) {
    console.log("An error occured in the getToken funciton", e);
    alert("A problem occured");
    throw "getToken() failed";
  }
}

export async function createAccount(userDetails) {
  let { patientFirst, patientLast, email, patientMobile, dob } = userDetails;
  const token = await getToken();
  console.log("input dob", dob);
  console.log("output dob", formatDate(dob));
  if (!email) {
    email = uuidv4();
  }
  const config = {
    method: "post",
    url: base_url + "/user_management/add_patients",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      auth: token,
    },
    data: JSON.stringify({
      first_name: patientFirst,
      last_name: patientLast,
      dob: formatDate(dob),
      email: email,
      mobile: patientMobile,
      org_id: org_id,
    }),
  };
  try {
    const res = await axios(config);
    return res;
  } catch (e) {
    console.log("An error occured in the createAccount function", e);
    alert(
      "A problem occured while creating your account. The email address might already exist"
    );
    throw "sendInvite failed";
  }
}

export async function sendInvite(patientMobile) {
  console.log("sendInvite called");
  const config = {
    method: "post",
    url: base_url + "/user_management/twilio_sms",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ mobile_number: patientMobile }),
  };
  try {
    const res = await axios(config);
    console.log("res", res);
    return res.data;
  } catch (e) {
    console.log("An error occured in the sendInvite function", e);
    alert("A problem occured with twilio");
    throw "sendInvite failed";
  }
}

export async function createChat(patientMobile) {
  const config = {
    method: "post",
    url: "https://us-west2-afya-production.cloudfunctions.net/create_session",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ patient_mobile: patientMobile }),
  };
  try {
    const res = await axios(config);
    if (res.status === 200) {
      alert("Afya App Invitation Sent!");
    }
  } catch (e) {
    console.log("An error occured in the createChat function", e);
    alert("A problem occured with creating a chat");
  }
}

export async function testReq() {
  const config = {
    method: "get",
    url: "https://jsonplaceholder.typicode.com/todos/1",
    // data: {},
  };
  const res = await axios(config);
  console.log("res", res);
  return res;
}
